body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
}

#root button {
    font-size: 1.4rem;
}

p.MuiTypography-root {
    font-size: 1.5rem;
}

span.MuiTypography-root {
    font-size: 2rem;
}

#root h6 {
    font-size: 1.5rem;
}

.MuiCalendarPicker-root .MuiPickersDay-root {
  border: 1px solid #4caf50;
}

.MuiCalendarPicker-root .MuiPickersDay-root.Mui-disabled, .MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected {
  border:none;
}

.MuiCalendarPicker-root .MuiPickersDay-root.Mui-disabled.MuiPickersDay-today {
  border: 1px solid rgba(0, 0, 0, 0.2);
}